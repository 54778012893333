import React from 'react'

const About = () => {
  return (
    <div id='about' className='max-w-[1140px] m-auto w-full p-4 py-16'>
        <h2 className='text-center text-black p-4 text-4xl '>About Us</h2>
        <div className='text-[22px]  py-4'>
            {/* <p className='about'>Humble Glorious Glass Inc. serves home builders, contractors, 
            and homeowners in Greater Toronto Area as a prominent provider of custom glass and mirror products.
             We are able to provide our customers with highly customized solutions for all of their glass requirements thanks to our in-house fabrication capabilities.
              We have endured the test of time because we have always prioritized our clients' requirements. 
              Today, we offer a wide range of custom mirror and glass products, such as: Shower and tub enclosures made of frameless glass, guards made of glass, Glass railings,
               antique mirrors, segmented mirrors, glass floors, mirror walls, and a wide range of other custom glass products are available. We collaborate closely with your homeowners,
                contractors, and design team to ensure that we comprehend your needs and provide a product that meets your expectations.</p> */}

                <p className=' font-style-text'>
                Welcome to Humble Glorious Glass, your premier destination for exquisite glass products in the Greater Toronto Area (GTA).
                 We take pride in offering a wide range of high-quality glass solutions that will elevate your space and leave a lasting impression.
                  Our commitment to exceptional craftsmanship and customer satisfaction has made us a trusted name in the industry.

                At Humble Glorious Glass, we understand the power of beautiful, functional glass designs.
                  Whether you're looking to enhance your home, office, or commercial establishment,
                our team of experienced professionals is dedicated to providing you with top-notch products tailored to your unique needs.

                <br />
                <br />
                When you choose Humble Glorious Glass, you're choosing a company that values quality, craftsmanship, and customer satisfaction above all else. 
                We take great pride in our ability to create stunning glass designs that enhance the beauty and functionality of any space.
                  <br />
                  <br />
Contact us today to discuss your glass needs and let us bring a touch of elegance and sophistication to your environment. 
Trust Humble Glorious Glass for all your glass solutions in the GTA and beyond.
                </p>

        </div>
    </div>
  )
}

export default About